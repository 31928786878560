import { ClinicProvider } from '../../pages/api/nearby-clinics.api'
import ClinicSearch from './clinic-search'
import * as UI from '@/ui'

type ClinicLocationsModalProps = {
  providers: ClinicProvider[]
  show: boolean
  onClinicChange: (clinicId: string) => void
  onClose: () => void
}

export const ClinicLocationsModal = ({ providers, show, onClinicChange, onClose }: ClinicLocationsModalProps) => {
  return (
    <UI.Modal color="light" show={show} onClose={onClose}>
      <UI.Block className="w-full max-w-lg">
        <UI.Block gap="xs">
          <UI.Heading as="p" size={{ default: 'xs', lg: 'small' }}>
            Find a clinic
          </UI.Heading>
          <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="leading-tight">
            Use your postcode or location to find your nearest Selph partner clinic. We&apos;ll email you details of how
            to book your appointment after you checkout.
          </UI.Paragraph>
        </UI.Block>

        <ClinicSearch
          providers={providers}
          onClinicChange={onClinicChange}
          onClose={onClose}
          containerHeight="h-[37vh] md:h-[39vh]"
        />
      </UI.Block>
    </UI.Modal>
  )
}

export default ClinicLocationsModal
