import { Radio } from '@headlessui/react'
import { Clinic } from '../../pages/api/nearby-clinics.api'
import { cn } from '../../helpers/tailwind'
import Tick_Icon from '@/images/icons/tick_icon.svg?svgr'
import * as UI from '@/ui'

const ClinicLocationCard = ({ clinic, checkbox }: { clinic: Clinic; checkbox?: boolean }) => {
  const distance = getDistance(clinic)

  return (
    <Radio
      key={clinic.name}
      value={clinic}
      className={({ checked }) =>
        `w-full ${
          checked ? 'bg-selphGreen-400 text-selphWhite-500' : 'bg-selphWhite-500 text-selphBlack'
        } group my-1.5 cursor-pointer rounded-xl px-2 py-2 hover:bg-selphGreen-400 focus:outline-hidden`
      }
    >
      {({ checked }) => (
        <div id="ClinicLocationCard" className="flex items-center justify-between gap-x-2">
          <div className="flex items-center gap-x-2">
            {checkbox && (
              <span className="w-1/12">
                {checked ? (
                  <span className="flex size-5 shrink-0 items-center justify-center rounded-full border border-selphOrange-500 bg-selphOrange-500 p-0.5 text-selphWhite-300">
                    <Tick_Icon className="h-4" />
                  </span>
                ) : (
                  <span className="block size-5 shrink-0 justify-center rounded-full border border-selphOrange-500 bg-selphWhite-300 group-hover:bg-selphOrange-500" />
                )}
              </span>
            )}

            <UI.Block gap="xs">
              <UI.Paragraph
                color={checked ? 'white' : 'black'}
                weight="regular"
                className="group-hover:text-selphWhite-500"
              >
                {clinic.name}
              </UI.Paragraph>

              <UI.Paragraph
                size={{ default: 'small', sm: 'medium' }}
                color={checked ? 'white' : 'black'}
                className="leading-tight group-hover:text-selphWhite-500"
              >
                {clinic.address.join(', ')}
              </UI.Paragraph>
              <div className="flex items-center gap-x-2">
                <UI.Link
                  to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    `${clinic.name}, ${clinic.address.join(', ')}`,
                  )}`}
                  className={cn(
                    checked ? 'text-selphWhite-500' : 'text-selphBlack',
                    'font-normal group-hover:text-selphWhite-500 hover:border-selphAmber-500 hover:text-selphAmber-500',
                  )}
                >
                  Get directions
                </UI.Link>
                <UI.Badge
                  color="outline"
                  className={cn(
                    checked ? 'text-selphWhite-500' : '',
                    'whitespace-nowrap group-hover:text-selphWhite-500 sm:hidden',
                  )}
                  size="small"
                >
                  {distance}
                </UI.Badge>
              </div>
            </UI.Block>
          </div>
          <span className="hidden sm:inline">
            <UI.Badge
              color="outline"
              className={cn(checked ? 'text-selphWhite-500' : '', 'whitespace-nowrap group-hover:text-selphWhite-500')}
            >
              {distance}
            </UI.Badge>
          </span>
        </div>
      )}
    </Radio>
  )
}

export default ClinicLocationCard

const getDistance = (clinic: Clinic) => {
  const distance = clinic.distanceMiles.toFixed(0)
  const unit = distance === '1' ? 'mile' : 'miles'

  return `${distance} ${unit}`
}
