import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import isEmpty from 'lodash-es/isEmpty'
import { Clinic, ClinicProvider, NearbyClinicsRequest, NearbyClinicsResponse } from '../../pages/api/nearby-clinics.api'
import { cn } from '../../helpers/tailwind'
import ClinicLocationCard from './clinic-location-card'
import MapPinIcon from '@/images/icons/mapPin_icon.svg?svgr'
import * as UI from '@/ui'

export type ClinicSearchProps = {
  providers: ClinicProvider[]
  containerHeight: string // e.g. "h-[40vh] md:h-[50vh]"
  onClinicChange?: (clinicId: string) => void
  onClose?: () => void
}

export const ClinicSearch = ({ providers, containerHeight, onClinicChange, onClose }: ClinicSearchProps) => {
  const [isLoadingLocations, setIsLoadingLocations] = useState(false)
  const [nearbyClinics, setNearbyClinics] = useState<Clinic[]>()
  const [isPostcodeValid, setIsPostcodeValid] = useState(true)
  const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(true)
  const [clinic, setClinic] = useState<Clinic | null>(null)

  useEffect(() => {
    //  cannot set this in state initialisation as the "navigator" object is not available on server
    // need to wait on client side to run the useEffect hook
    setIsGeolocationEnabled('geolocation' in navigator)
  }, [])

  const searchByPostcode = async ({ postcode }: { postcode: string }) => {
    setIsGeolocationEnabled(true)

    if (postcode.length <= 3) {
      setIsPostcodeValid(false)
      return
    }

    setIsPostcodeValid(true)
    setIsLoadingLocations(true)
    setNearbyClinics([])

    const locations = await fetchLocations({ postcode, providers })

    setNearbyClinics(locations)
    setIsLoadingLocations(false)
  }

  const getNearbyClinics = async () => {
    setIsPostcodeValid(true)

    const myLocation = await getMyLocation()

    if (myLocation) {
      const locations = await fetchLocations({ lat: myLocation.lat, lng: myLocation.lng, providers })

      setNearbyClinics(locations)
    }
  }

  const getMyLocation = () => {
    return new Promise<{ lat: number; lng: number } | undefined>((resolve) => {
      setIsLoadingLocations(true)
      setNearbyClinics([])

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLoc = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }

            setIsLoadingLocations(false)
            resolve(userLoc)
          },

          (error) => {
            if (error.code === 1) {
              setIsGeolocationEnabled(false)
              setIsLoadingLocations(false)
            } else {
              setIsLoadingLocations(false)
            }
          },
        )
      } else {
        setIsLoadingLocations(false)
      }
    })
  }

  const fetchLocations = async ({ lat, lng, postcode, providers }: NearbyClinicsRequest) => {
    const fetchBody = postcode ? JSON.stringify({ postcode, providers }) : JSON.stringify({ lat, lng, providers })

    const response = await fetch('/api/nearby-clinics', {
      body: fetchBody,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    if (!response.ok) {
      setIsPostcodeValid(false)
      setIsLoadingLocations(false)
    }

    const body: NearbyClinicsResponse = await response.json()

    return body.clinics
  }

  const handleSubmit = () => {
    if (clinic && onClinicChange && onClose) {
      onClinicChange(clinic.id)
      onClose()
    }
  }

  return (
    <>
      <UI.Form.Form<{ postcode: string }>
        onSubmit={searchByPostcode}
        initialValues={{ postcode: '' }}
        validationSchema={UI.Form.validation.schema({
          postcode: { label: 'Postcode', type: 'string', criteria: 'alphanumericExtra', required: true },
        })}
      >
        <UI.Block gap="xs">
          <div className="flex max-w-xs items-start justify-between gap-x-5">
            <UI.Form.Text
              name="postcode"
              placeholder="Postcode"
              boxSize={{ default: 'full', sm: 'medium' }}
              errorMessage={isPostcodeValid ? '' : 'Invalid postcode'}
            />

            <UI.Form.Submit
              size={{ default: 'small', sm: 'medium' }}
              showError={false}
              isRunning={isLoadingLocations}
              disabled={isLoadingLocations}
            >
              Search
            </UI.Form.Submit>
          </div>
          <UI.Button
            className="flex items-stretch"
            type="text"
            disabled={isLoadingLocations}
            onClick={() => getNearbyClinics()}
          >
            <MapPinIcon className="mr-2 h-5 w-5" />
            <span>Use your location</span>
          </UI.Button>
        </UI.Block>
      </UI.Form.Form>

      <UI.Block gap="large" className={cn(!isEmpty(nearbyClinics) ? containerHeight : '', 'overflow-y-auto')}>
        {isLoadingLocations && (
          <UI.Block gap="large" className="w-full">
            {Array.from({ length: 4 }).map((_, index) => (
              <React.Fragment key={index}>
                <UI.LoadingSkeleton numberOfLines={3} lineHeight="small" gap="small" />
                <UI.Divider />
              </React.Fragment>
            ))}
          </UI.Block>
        )}

        {!isGeolocationEnabled && (
          <UI.Message color="warning" title="Enable Location Services" canDismiss={false}>
            <UI.Paragraph color="black">
              To use this feature, please enable location services in your browser settings. Once enabled, refresh the
              page and try again.
            </UI.Paragraph>
          </UI.Message>
        )}

        {!isEmpty(nearbyClinics) && (
          <RadioGroup value={clinic} onChange={(selectedClinic: Clinic) => setClinic(selectedClinic)}>
            <div className="flex w-full flex-col items-center gap-1 pr-4">
              {(nearbyClinics || []).map((clinic) => (
                <ClinicLocationCard key={clinic.id} clinic={clinic} checkbox={!!onClinicChange} />
              ))}
            </div>
          </RadioGroup>
        )}
      </UI.Block>

      {!isEmpty(nearbyClinics) && onClinicChange && (
        <UI.Button
          size="full"
          onClick={handleSubmit}
          rootClassName="w-[96%] max-w-md md:max-w-xl mx-auto"
          disabled={!clinic}
        >
          Choose Clinic
        </UI.Button>
      )}
    </>
  )
}

export default ClinicSearch
