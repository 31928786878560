import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { TuliBookingApiResponse } from '../../../pages/api/clinic-booking.api'
import { TuliBookingInfoApiResponse, TuliBookingInfoRequest } from '../../../pages/api/clinic-booking-info.api'
import { getParam } from '../../../helpers/params'
import { getFormattedDateTime } from '../../../helpers/date'
import * as UI from '@/ui'

export type BookingDetailsProps = {
  bookingCode: string
}

export const BookingDetails = ({ bookingCode }: BookingDetailsProps) => {
  const { query: params, isReady: isRouterReady } = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [bookingData, setBookingData] = useState<TuliBookingInfoApiResponse[0] | null>(null)

  useEffect(() => {
    if (!isRouterReady) return
    const sid = getParam(params.sid)
    const booking = JSON.parse(sessionStorage.getItem(`booking__${sid}`) as string) as TuliBookingApiResponse | null

    const bookingCode = booking?.['bookingCode']

    if (!bookingCode) return
    ;(async () => {
      setIsLoading(true)
      const data = await fetchBookingDetails({ bookingCode })

      setBookingData(data[0])
      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCode, isRouterReady])

  if (isLoading) {
    return <UI.LoadingSkeleton numberOfLines={8} gap="large" color="bg-gray-400" className="mx-auto mt-6 max-w-3xl" />
  }

  if (!bookingData) {
    return <UI.Heading size="xs">No booking data found</UI.Heading>
  }

  return (
    <UI.Panel.Panel>
      <UI.Panel.Head className="text-center text-2xl font-semibold md:rounded-t-md">Appointment Details</UI.Panel.Head>

      <UI.Panel.Body gap="none" className="md:flex md:items-center">
        <div className="p-2 text-center md:basis-3/6 md:p-4 md:text-left">
          <UI.Paragraph size={{ default: 'large', md: 'xl' }} className="md:pl-4">
            {bookingData.pharma.name}
          </UI.Paragraph>

          <UI.Paragraph size={{ default: 'medium', md: 'large' }} className="md:pl-4">
            {`${bookingData.pharma.address1}, ${bookingData.pharma.city}, ${bookingData.pharma.postCode}`}
          </UI.Paragraph>

          <UI.Link
            to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
              `${bookingData.pharma.name}, ${bookingData.pharma.address1}, ${bookingData.pharma.city}, ${bookingData.pharma.postCode}`,
            )}`}
            className="md:pl-4"
          >
            Get directions
          </UI.Link>
        </div>

        {bookingData && (
          <UI.Paragraph
            size={{ default: 'large', md: 'xl' }}
            className="p-4 text-center md:mr-4 md:basis-3/6 md:text-end"
          >
            {getFormattedDateTime(bookingData.time)}
          </UI.Paragraph>
        )}
      </UI.Panel.Body>
    </UI.Panel.Panel>
  )
}

export default BookingDetails

export const fetchBookingDetails = async ({ bookingCode }: TuliBookingInfoRequest) => {
  const response = await fetch('/api/clinic-booking-info', {
    body: JSON.stringify({ bookingCode }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  if (!response.ok) {
    // console.error('Error fetching clinic data:', response)
  }

  const data: TuliBookingInfoApiResponse = await response.json()

  if (!data) {
    // console.error('Error parsing clinic data:', data)
  }

  return data
}
