import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { ActivateKitProps } from '../../../pages/account/test/activate/index.page'
import { ClinicDataResponse } from '../../../pages/api/clinic-info.api'
import { useQuiz } from '../../ui/quiz/use-quiz'
import { ClinicProvider } from '../../../pages/api/nearby-clinics.api'
import { TuliBookingApiResponse } from '../../../pages/api/clinic-booking.api'
import { CustomerKitRequestByIdQuery } from '../../../gql'
import { getParam } from '../../../helpers/params'
import { cn } from '../../../helpers/tailwind'
import ClinicSelector from './clinic-selector'
import ClinicDailySchedule from './clinic-daily-schedule'
import BookingDetails from './booking-details'
import * as UI from '@/ui'

type KitActivationPageFourProps = {
  kitRequest: NonNullable<CustomerKitRequestByIdQuery['kitRequestById']>
  redirectToBooking: boolean
  setRedirectToBooking: (val: boolean) => void
  bookingSuccessful: boolean
}

type KitActivationPageFourFormValues = Partial<Pick<ActivateKitProps, 'clinicId' | 'clinicTimeSlot'>>

const providers: ClinicProvider[] = ['tuli']

const KitActivationPageFour = ({
  kitRequest,
  redirectToBooking,
  setRedirectToBooking,
  bookingSuccessful,
}: KitActivationPageFourProps) => {
  const { query: params, isReady: isRouterReady } = useRouter()
  const quiz = useQuiz()

  const [selectedClinicIdentifier, setSelectedClinicIdentifier] = useState<string>('')
  const [selectedClinicTimeSlot, setSelectedClinicTimeSlot] = useState<string>('')
  const [clinicData, setClinicData] = useState<ClinicDataResponse | null>(null)
  const [bookingDetails, setBookingDetails] = useState<TuliBookingApiResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isRouterReady) return

    if (params?.sid) {
      const sid = getParam(params.sid)
      const bookingData = sessionStorage.getItem(`booking__${sid}`)
      const parsedBookingData = bookingData ? JSON.parse(bookingData) : null

      setBookingDetails(parsedBookingData)
    }
  }, [isRouterReady, params?.sid, bookingSuccessful])

  const handleClinicSelect = useCallback(
    (clinicId: string, data: ClinicDataResponse | null) => {
      if (quiz.values.clinicId === clinicId) {
        setSelectedClinicTimeSlot(quiz.values.clinicTimeSlot)
      } else {
        setSelectedClinicIdentifier(clinicId)
        setSelectedClinicTimeSlot('')
      }

      setClinicData(data)
    },
    // avoid triggering this useCallback on the "selectedClinicIdentifier" update
    // this is to pre-select the already selected "clinicTimeSlot" when returning from quiz-page-five to quiz-page-four
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedClinicIdentifier, setClinicData, setSelectedClinicTimeSlot, quiz.values],
  )

  const isValidClinicId = checkIfClinicIdIsValid(selectedClinicIdentifier, clinicData)

  const initialValues: KitActivationPageFourFormValues = {
    clinicId: selectedClinicIdentifier || '',
    clinicTimeSlot: selectedClinicTimeSlot || '',
  }

  const errorMsg = () => {
    switch (true) {
      case !isValidClinicId:
        return 'Need to select a clinic'
      case !bookingDetails && (!selectedClinicIdentifier || !selectedClinicTimeSlot):
        return 'Need to select a time slot'
      default:
        return ''
    }
  }

  const isContinueButtonDisabled = !bookingDetails && (!selectedClinicIdentifier || !selectedClinicTimeSlot)

  return (
    <UI.Quiz.PageForm<KitActivationPageFourFormValues>
      name="clinicBooking"
      initialValues={initialValues}
      onSubmit={() => {
        setRedirectToBooking(false)
      }}
    >
      {({ setFieldValue }) => (
        <>
          <UI.Heading size="small">Clinic Blood Draw Appointment</UI.Heading>

          {!bookingDetails ? (
            <>
              <UI.Paragraph>
                Select a clinic and book an appointment to have your blood drawn. You will need to take your kit with
                you.
              </UI.Paragraph>

              <UI.Block gap="xxl" className="mx-auto max-w-4xl">
                <ClinicSelector
                  kitRequest={kitRequest}
                  providers={providers}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onClinicSelect={handleClinicSelect}
                  selectedClinicIdentifier={selectedClinicIdentifier}
                  setFieldValue={setFieldValue}
                />

                {/* Keep this as "hidden". It's beeig used to simulate Quiz inputs 
                which will hold the values to be stored in the quiz form values on the sessionStorage. */}
                <div className="hidden w-40">
                  <UI.Quiz.Question
                    type="text"
                    name="clinicId"
                    placeholder={selectedClinicIdentifier || 'undefined - ' + quiz.values.clinicId}
                  />
                  <UI.Quiz.Question
                    type="text"
                    name="clinicTimeSlot"
                    placeholder={selectedClinicTimeSlot || 'undefined - ' + quiz.values.clinicTimeSlot}
                  />
                </div>

                {isValidClinicId && (
                  <ClinicDailySchedule
                    data={clinicData?.availability || []}
                    isLoading={isLoading}
                    selectedClinicTimeSlot={selectedClinicTimeSlot}
                    setSelectedClinicTimeSlot={setSelectedClinicTimeSlot}
                    setFieldValue={setFieldValue}
                    redirectToBooking={redirectToBooking}
                    useTimeOnly={true}
                  />
                )}
              </UI.Block>
            </>
          ) : (
            <UI.Block gap="medium">
              <UI.Heading size="xxs">Your clinic appointment has already been confirmed.</UI.Heading>
              <BookingDetails bookingCode={bookingDetails.bookingCode} />
            </UI.Block>
          )}

          <div className={cn(isContinueButtonDisabled ? 'pb-4' : '', 'flex items-center justify-between')}>
            <UI.Quiz.BackButton size="fit">Back</UI.Quiz.BackButton>
            <UI.Quiz.SubmitButton size="fit" disabled={isContinueButtonDisabled} errorMessage={errorMsg()}>
              Continue
            </UI.Quiz.SubmitButton>
          </div>
        </>
      )}
    </UI.Quiz.PageForm>
  )
}

export default KitActivationPageFour

export const checkIfClinicIdIsValid = (selectedClinicIdentifier: string, clinicData: ClinicDataResponse | null) => {
  // check if the clinicId is set from an AdminOrder, meaning the user needs to chose an clinic from the specified provider
  const id = selectedClinicIdentifier ? selectedClinicIdentifier.split('-')[1] !== '9999' : false

  // check if we have a clinicId value in the response, meaning that we have valid data returned from the TULI API
  const hasData = clinicData?.id

  return id && hasData
}
