import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { UseQuizResult } from '../../ui/quiz/use-quiz'
import { ClinicDataResponse, ClinicInfoRequest } from '../../../pages/api/clinic-info.api'
import { ClinicProvider } from '../../../pages/api/nearby-clinics.api'
import { CustomerKitRequestByIdQuery } from '../../../gql'
import ClinicLocationsModal from '../../product/clinic-locations-modal'
import { getParam } from '../../../helpers/params'
import { cn } from '../../../helpers/tailwind'
import { checkIfClinicIdIsValid } from './quiz-page-four'
import * as UI from '@/ui'

type QuizSessionValues = {
  currentPage: string | undefined
  pages: UseQuizResult['pages']
  values: UseQuizResult['values']
}

type ClinicSelectorProps = {
  kitRequest: NonNullable<CustomerKitRequestByIdQuery['kitRequestById']>
  providers: ClinicProvider[]
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  onClinicSelect: (clinicId: string, clinicData: ClinicDataResponse | null) => void
  selectedClinicIdentifier: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const ClinicSelector = ({
  kitRequest,
  providers,
  isLoading,
  setIsLoading,
  onClinicSelect,
  selectedClinicIdentifier,
  setFieldValue,
}: ClinicSelectorProps) => {
  const { query: params, isReady: isRouterReady } = useRouter()
  const [seeClinicLocations, setSeeClinicLocations] = useState(false)
  const [clinicData, setClinicData] = useState<ClinicDataResponse | null>(null)
  const [clinicIdentifier, setClinicIdentifier] = useState<string>('')

  useEffect(() => {
    if (!isRouterReady || !kitRequest.orderedProduct?.bloodCollectionOption?.clinicId) {
      setClinicIdentifier('')
      return
    }

    const clinicId = `${kitRequest.orderedProduct.bloodCollectionOption.provider}-${kitRequest.orderedProduct.bloodCollectionOption.clinicId}`

    const setClinicAndFieldValue = (id: string) => {
      setClinicIdentifier(id)
      setFieldValue('clinicId', id, false)
    }

    if (selectedClinicIdentifier) {
      setClinicAndFieldValue(selectedClinicIdentifier)
      return
    }

    if (!params?.sid) {
      setClinicAndFieldValue(clinicId)
      return
    }

    const sid = getParam(params.sid)
    const quizData = sessionStorage.getItem(`quiz__${sid}`)
    const parsedQuizData: QuizSessionValues | null = quizData ? JSON.parse(quizData) : null

    if (parsedQuizData?.values?.clinicId) {
      setClinicAndFieldValue(parsedQuizData.values.clinicId)
    } else {
      setClinicAndFieldValue(clinicId)
    }
  }, [
    selectedClinicIdentifier,
    isRouterReady,
    params?.sid,
    kitRequest.orderedProduct?.bloodCollectionOption,
    setFieldValue,
  ])

  const isValidClinicId = checkIfClinicIdIsValid(selectedClinicIdentifier, clinicData)

  useEffect(() => {
    if (!clinicIdentifier) return

    const fetchClinicInfo = async () => {
      setIsLoading(true)

      const data = await fetchClinicData({ id: clinicIdentifier })

      setClinicData(data)
      onClinicSelect(clinicIdentifier, data)

      setIsLoading(false)
    }

    fetchClinicInfo()
  }, [clinicIdentifier, onClinicSelect, setIsLoading])

  const handleClinicChange = (val: string) => {
    setFieldValue('clinicId', val, false)
    setFieldValue('clinicTimeSlot', '', false)
    onClinicSelect(val, null)
  }

  return (
    <UI.Panel.Panel>
      <UI.Panel.Head className="text-center text-2xl font-semibold md:rounded-t-md">
        {isValidClinicId ? 'Selected clinic' : 'Clinic selection'}
      </UI.Panel.Head>

      {isLoading ? (
        <UI.LoadingSkeleton numberOfLines={4} lineHeight="small" color="bg-gray-400" className="mx-auto p-6" />
      ) : (
        <>
          <UI.Panel.Body gap="none" className="md:flex md:items-center">
            {isValidClinicId && (
              <div className="p-2 md:basis-4/6 md:p-4">
                <UI.Paragraph size={{ default: 'large', md: 'xl' }} weight="regular" className="pl-4">
                  {clinicData?.name}
                </UI.Paragraph>

                <UI.Paragraph size={{ default: 'medium', md: 'large' }} className="pl-4">
                  {clinicData?.address.join(', ')}
                </UI.Paragraph>

                <UI.Link
                  to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    `${clinicData?.name}, ${clinicData?.address.join(', ')}`,
                  )}`}
                  className="pl-4"
                >
                  Get directions
                </UI.Link>
              </div>
            )}

            <div
              className={cn(isValidClinicId ? 'md:mr-4 md:basis-2/6' : 'md:basis-full', 'p-4 text-center md:text-end')}
            >
              <UI.Button onClick={() => setSeeClinicLocations(!seeClinicLocations)} className="w-full" size="medium">
                {isValidClinicId ? 'Find another clinic' : 'Find a clinic'}
              </UI.Button>
            </div>
          </UI.Panel.Body>

          <ClinicLocationsModal
            providers={providers}
            show={seeClinicLocations}
            onClinicChange={(val) => {
              handleClinicChange(val)
              setSeeClinicLocations(false)
            }}
            onClose={() => setSeeClinicLocations(false)}
          />
        </>
      )}
    </UI.Panel.Panel>
  )
}

export default ClinicSelector

export const fetchClinicData = async ({ id }: ClinicInfoRequest) => {
  const response = await fetch('/api/clinic-info', {
    body: JSON.stringify({ id }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  if (!response.ok) {
    return null
  }

  const data: ClinicDataResponse = await response.json()

  return data
}
