import { useEffect } from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { fromIsoString, toDetailedDate, toTime } from '../../../helpers/date'
import UpArrowIcon from '@/images/icons/arrowUp_icon.svg?svgr'
import * as UI from '@/ui'

type TimeInterval = {
  startTime: string
  endTime: string
}

type TimeSlot = {
  date: string
  times: (string | TimeInterval)[]
}

type ClinicDailyScheduleProps = {
  data: TimeSlot[]
  isLoading: boolean
  selectedClinicTimeSlot: string
  setSelectedClinicTimeSlot: (timeSlot: string) => void
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
  redirectToBooking: boolean
  title?: string
  description?: string
  useTimeOnly?: boolean
}

const slotsToShow = 3

const isTimeInterval = (time: string | TimeInterval): time is TimeInterval => {
  return typeof time === 'object' && 'startTime' in time && 'endTime' in time
}

const getTimeDisplay = (time: string | TimeInterval): string => {
  if (isTimeInterval(time)) {
    return `${toTime(time.startTime).toLowerCase()} - ${toTime(time.endTime).toLowerCase()}`
  }
  return toTime(time).toLowerCase()
}

const getTimeValue = (time: string | TimeInterval, date: string, useTimeOnly: boolean): string => {
  if (isTimeInterval(time)) {
    return useTimeOnly ? time.startTime : `${date}|${time.startTime}`
  }
  return useTimeOnly ? time : `${date}|${time}`
}

const getDateTimeValue = (time: string | TimeInterval): string => {
  if (isTimeInterval(time)) {
    return time.startTime
  }
  return time
}

export const ClinicDailySchedule = ({
  data,
  isLoading,
  selectedClinicTimeSlot,
  setSelectedClinicTimeSlot,
  setFieldValue,
  redirectToBooking,
  title,
  description,
  useTimeOnly = false,
}: ClinicDailyScheduleProps) => {
  useEffect(() => {
    if (redirectToBooking) {
      setSelectedClinicTimeSlot('')
    }
  }, [redirectToBooking, setSelectedClinicTimeSlot])

  const defaultOpenIndex = selectedClinicTimeSlot
    ? data?.findIndex((day) =>
        day.times.some((time) => getTimeValue(time, day.date, useTimeOnly) === selectedClinicTimeSlot),
      )
    : 0

  return (
    <UI.Panel.Panel>
      <UI.Panel.Head className="text-center text-2xl font-semibold md:rounded-t-md">
        {title || 'Choose a time'}
      </UI.Panel.Head>
      <UI.Panel.Body>
        {description && <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>{description}</UI.Paragraph>}
        {isLoading ? (
          <UI.LoadingSkeleton numberOfLines={8} lineHeight="small" color="bg-gray-400" className="mx-auto p-6" />
        ) : (
          <>
            {data?.length !== 0 ? (
              <div className="space-y-4">
                {(data || []).map((day, index) => (
                  <Disclosure
                    key={index}
                    as="div"
                    className="my-4 rounded-lg border"
                    defaultOpen={index === defaultOpenIndex}
                  >
                    {({ open }) => (
                      <>
                        <DisclosureButton
                          as="div"
                          className="focus-visible:ring-opacity-75 flex w-full flex-wrap items-center gap-x-4 space-y-2 rounded-lg bg-selphWhite-300 px-2 py-2 text-left text-sm font-medium hover:cursor-pointer hover:bg-selphWhite-500 focus:outline-hidden focus-visible:ring-3"
                        >
                          <span className="min-w-[220px] flex-1 text-left text-base whitespace-nowrap sm:text-lg">
                            {getDate(day.date)}
                          </span>
                          {!open ? (
                            <div className="flex grow flex-wrap items-center gap-x-2 gap-y-1 md:justify-end md:whitespace-nowrap">
                              {day.times.slice(0, slotsToShow).map((timeSlot) => (
                                <time
                                  key={getTimeValue(timeSlot, day.date, useTimeOnly)}
                                  dateTime={fromIsoString(getDateTimeValue(timeSlot)).toISO() || ''}
                                >
                                  <UI.Form.Button
                                    name="timeSlot"
                                    theme="basic"
                                    size="small"
                                    color={
                                      selectedClinicTimeSlot === getTimeValue(timeSlot, day.date, useTimeOnly)
                                        ? 'amber'
                                        : 'amber_outline'
                                    }
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const timeValue = getTimeValue(timeSlot, day.date, useTimeOnly)
                                      setSelectedClinicTimeSlot(timeValue)
                                      setFieldValue('clinicTimeSlot', timeValue, false)
                                    }}
                                    className="min-w-20 text-sm font-semibold md:min-w-24 md:text-base"
                                  >
                                    {getTimeDisplay(timeSlot)}
                                  </UI.Form.Button>
                                </time>
                              ))}

                              {day.times.length > slotsToShow && (
                                <span className="text-selphAmber-500">
                                  (...{day.times.length - slotsToShow} more slots)
                                </span>
                              )}
                            </div>
                          ) : (
                            <span className="ml-3 flex items-center">
                              <UpArrowIcon className="size-4" aria-hidden="true" />
                            </span>
                          )}
                        </DisclosureButton>

                        <DisclosurePanel className="px-4 pt-4 pb-2 text-sm">
                          <div className="flex flex-wrap justify-center gap-x-6 gap-y-3 self-end md:mx-4 md:justify-start">
                            {day.times.map((timeSlot) => (
                              <time
                                key={getTimeValue(timeSlot, day.date, useTimeOnly)}
                                dateTime={fromIsoString(getDateTimeValue(timeSlot)).toISO() || ''}
                              >
                                <UI.Form.Button
                                  name="timeSlot"
                                  theme="basic"
                                  size="small"
                                  color={
                                    selectedClinicTimeSlot === getTimeValue(timeSlot, day.date, useTimeOnly)
                                      ? 'amber'
                                      : 'amber_outline'
                                  }
                                  onClick={() => {
                                    const timeValue = getTimeValue(timeSlot, day.date, useTimeOnly)
                                    setSelectedClinicTimeSlot(timeValue)
                                    setFieldValue('clinicTimeSlot', timeValue, false)
                                  }}
                                  className="min-w-20 text-sm font-semibold md:min-w-24 md:text-base"
                                >
                                  {getTimeDisplay(timeSlot)}
                                </UI.Form.Button>
                              </time>
                            ))}
                          </div>
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            ) : (
              <UI.Paragraph size={{ default: 'large', md: 'xl' }} className="p-6 text-center">
                There are no time slots available in the near future. Please try another location.
              </UI.Paragraph>
            )}
          </>
        )}
      </UI.Panel.Body>
    </UI.Panel.Panel>
  )
}

export default ClinicDailySchedule

export const getDate = (dateStr: string): JSX.Element => {
  const { dayOfWeek, restOfDate } = toDetailedDate(dateStr)

  return (
    <time dateTime={fromIsoString(dateStr).toISO() || ''}>
      <span className="font-semibold">{dayOfWeek}</span> <span>{restOfDate}</span>
    </time>
  )
}
